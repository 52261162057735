export const lang = {
    label: 'Settings',

    search: 'Search',
    save: 'Save',
    cancel: 'Cancel',
    actions: '',

    update_success: 'Setting has been change',
    no_modality_found: 'Modality not found',

    pacs: 'PACS',
    utilities: 'Utilities',

    pacs_general: {
        label: 'Default settings',
        refresh: 'Refresh the PACS',

        app_related: 'Settings instance',

        app: 'General',
        name: 'Name of the instance',
        limit_find_result: 'Search limit result',
        limit_find_instances: 'Instance search limit',
        compression: 'Compression of the DICOM instances',
        case_sensitive: 'Sensitive case',
        strict_aet_comparison: 'Compare AET in a sensitive way to the case',
        private_dictionary: 'Private dictionary',
        query_retrieve_size: 'Maximal simultaneous number of Q/R requests',

        aet: 'Title of the DICOM application entity',
        port: 'Port',
        scp_timeout: 'Timeout before the DICOM associations be closed',
        association_close_delay: 'Second inactivity before the closing of the association',

        dicom_server_enabled: 'PACS Server',
        dicom_check_called_aet: 'Verify AET',
        dicom_check_modality_host: 'Verify the Ip address of the modality',

        dicom_web: 'DICOM WEB',
        dicom_web_enable: 'Activated',
        dicom_web_enable_wado: 'WADO activated',
        dicom_web_qido_case_sensitive: 'QIDO case sensitive',
        dicom_web_ssl: 'SSL',
        dicom_web_root: 'Root',
        dicom_web_wado_root: 'WADO root',
        dicom_web_wado_host: 'WADO host',
        dicom_web_wado_stow_max_instances: 'STOW Maximum number of instances',
        dicom_web_wado_stow_max_size: 'STOW Max size',

        explorer_setting: 'Explorer setting',
        explorer_available_modalities: 'Available modality',
        explorer_selected_modalities: 'Selected modalities',
        explorer_default_duration: 'Default duration',

        pacs_host:  'Pacs external URL (ex: https://pacs.demo.com)'
    },

    pacs_network: 'Network',
    pacs_transfer_syntax: 'Transfer syntax',

    pacs_modalities: 'Manage modalities',
    pacs_modalitiy_new: 'Add a new modality',
    modality_remove_confirm: 'Are you sure to delete the modality "{{title}}"',

    title: 'Title',
    host: 'Address',
    port: 'Port',
    allow_echo: 'Echo',
    allow_find: 'Find',
    allow_move: 'Move',
    allow_store: 'Store',

    ian_nodes: {
        label: 'Manage nodes (IAN)',
        new_item: 'Add nodes',
        remove_confirm: 'Are you sure to remove a node "{{title}}"',
    },

    pacs_dictionaries: 'Dictionaries',
    wado: 'WADO',
    staff: 'Manage staff',
    profiles: 'Manage profiles',
    staff_config: 'Manage users',

    archive: {
        label: 'Archive tasks',
        new: 'Add a task',
        trigger_time: 'Lunching time',
        is_active: 'Active',
        is_active_form: 'Active task',
        directory: 'Repertory',
        duration: 'Duration',
        used_space: 'Used space',
        modalities: 'Modality',
        hard_drive_status: 'Disk space (%)',
        task_remove_confirm: 'Are you sure to delete the task "{{title}}"',

        list: 'Task list',
        nothing_found: 'Archive not fount',

        patient_id: 'Patient ID',
        patient_name: 'Full Name',
        study_date: 'Study date',
        study_description: 'Description',
        size: 'Size',
        archived_at: 'Archived on',

        search: 'Search',
        study_date_search: 'Date',

        unarchive_confirm: 'Are you sure to unarchive "{{length}}" selected studies ?',
        remove_confirm: `
            <span>Are you sure to definitely delete "{{length}}" selected studies ?</span> <br />
            <strong class="ft-warn-color">This action is irrevocable !!</strong>
        `,

        initializing: 'Initialisation',
        uncompress: 'Uncompress',
        current_item: '{{patient_name}} - {{study_description}}',
        handling_item: 'Handling instance n° {{current}}/{{total}}',

        '12_months': 'One year',
        '9_months': '9 Months',
        '6_months': '6 Months',
        '3_months': '3 Months',
        '1_months': '1 Month',
        '15_days': '15 days',
        '7_days': 'One week',
    },

    dcm_upload: {
        dicomizer_btn: 'Dicomize',

        label: 'Upload DICOM file',
        drag_and_drop: 'Click or Drag and drop',
        files_count: '{{count}} files to be uploaded',

        file_progress: 'File N° {{file.index}}/{{total}}'
    },

    burner: {
        title: 'Title',
        label: 'Manage CD/DVD burners',
        local_directory: 'Shared directory (linux)',
        remote_directory: 'Original directory (windows)',
        trigger_time: 'Clean at',
        setup_new: 'Configure a new CD/DVD burner',
        remove_confirm: 'Are you sure to delete the engraving "{{title}}"',

        burners: 'Burners',
        bridge_is_active: 'Bridge is active',
        rules: 'Rules',
        network: 'Network',

        add_rule: 'Add rule',

        tasks: 'Current tasks',

        rule_aet: 'AE titre',
        burner_name: 'Name of the robot',

        // jobs
        burner_title: 'Robot title',
        job_patient: 'Patient',
        job_exam: 'Exam',
        job_exam_date: 'Exam date',
        job_status: 'Status',
        job_created_at: 'Job trigger time',
        refresh_jobs: 'Refresh jobs',

        // job status (epson related)
        JDF : 'Job created',
        RJD : 'Job Received',
        INP : 'Job in progress',
        DON : 'Job done',
        ERR : 'Job has error',
    },

    printing: 'Print',
    printer: {
        label: 'Manage printers',
        add_printer: 'Add a new printer',

        title: 'Name',
        aeTitle: 'AE Title',
        host: 'Address',
        port: 'Port',

        width: 'Width',
        height: 'Height',
        add_paper: 'Add paper',
        film_size: 'Film size ID',
        support_color: 'Support color',
        supported_types: 'Supported film types',
        supported_destinations: 'Supported Film destinations',
        supported_papers: 'Supported paper sizes - Landscape',

        remove_confirm: 'Are you sure to delete the printer "{{description}}"',
    },

    print_scp: {
        label: 'Printing bridge',

        rules: 'Rules',
        add_rule: 'Add a new rule',
        remove_rule_confirm: 'would you like to delete the rule "{{aet}}"',

        papers: 'Papers',
        network: 'Network',
        general: 'General',
        is_active_form: 'Active bridge',

        refresh: 'Refresh the bridge',

        aet: 'AET',
        port: 'Port',
        printer_name: 'Printer name',
        port_used: 'This port "{{port}}" is in use !!',

        cover_pages: 'Cover pages',
        booklet: 'Booklet',
        with_cover: 'With Cover',
        front_page: 'First page',
        last_page: 'Last page',
    },

    hl7: {
        label: 'HL7 server',
        server_port: 'Port',
        server_enable: 'Activated server',
        port_used: 'This port "{{port}}" is in use !!',
    },

    acl: {
        service: 'Establishment services',
        service_hint: 'Services using the application (required for user creations)',
        staff: 'Staff accounts',
        staff_hint: 'Staffs to use the application',

        burner_config: 'Burner robot',
        burner_config_hint: 'Burner robot (retrieve is required if to trigger jobs)',
        burner_config_generate_job: 'Trigger burn job',

        printer: 'Print client',
        printer_hint: 'Creation and update only for admins',
        printer_print: 'Trigger print',
        printer_generate_image: 'Browser preview',
        print_scp: 'Print server',
        print_scp_hint: 'Print scp (Access only for root)',

        app_setup: 'App settings',
        app_setup_hint: 'Access to application setup page',
        app_setup_update: 'Setting toolbar button',
        app_setup_reload_instance: 'Reload PACS instance',

        general_settings: 'PACS settings',
        general_settings_hint: 'PACS related settings (access only for root)',
        dicom_modality: 'DICOM modalities',
        dicom_modality_hint: 'Allowed modalities to query and retrieve the PACS',
        ian_nodes: 'IAN nodes',
        ian_nodes_hint: 'Instance availability notification listen nodes',

        archive_task: 'Archive tasks',
        archive_task_hint: 'PACS long term archive tasks (creation and update from root only)',
        archive_task_search: 'Search',
        archive_task_unarchive: 'Unarchive',

        explorer: 'Studies explorer',
        explorer_hint: 'PACS main interface (minimum access)',
        explorer_search: 'Search',
        explorer_download: 'Download',
    }
};
