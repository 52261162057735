import {Injectable} from '@angular/core';
import {UploadInstance} from '../models/upload-instance';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {catchError, debounce, filter, last, map, mergeMap, mergeScan, tap} from 'rxjs/operators';
import {assign, chain, map as loMap} from 'lodash';
import {from, Observable, of, timer} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {GeneralPurposeService} from '@ft/core';

@Injectable()
export class UploadHandlerService {

    constructor(
        private _http: HttpClient,
        private _dialog: MatDialog,
        private _generalPurpose: GeneralPurposeService
    ) {
    }

    // dicom upload related
    public uploadFiles(files: UploadInstance[]): Observable<UploadInstance> {
        const observables = chain(files)
            .map(file => new Object({file, subject: this._uploadFileItem(file)}))
            .value();

        return new Observable(subject => this._uploadChunk(0, observables, subject));
    }


    public dicomizeFiles(tags, files) {
        // tslint:disable-next-line:no-unused-expression
        return this._getUID()
            .pipe(
                mergeMap(uid => {
                    const observables = loMap(files, (file, index) => {
                        // handled tags and static ones
                        const handledTags = assign({
                            InstanceNumber: '1',
                            StudyInstanceUID: uid[0],
                            StationName: 'FIRE_PACS',
                            Manufacturer: 'FireThunder',
                            SeriesDescription: file.name,
                            SeriesNumber: (index + 1).toString(),
                            ImageComments: 'DICOMIZED BY FIRE_PACS',
                        }, tags);

                        return this._filesObservables(file, handledTags);
                    });

                    return from(observables);
                }),
                mergeScan((_, observable: any) => observable, [], 1),
                last()
            );
    }

    // private api for upload dcms
    private _uploadFileItem(item: UploadInstance) {
        const data: FormData = new FormData();
        data.append('file', item.file, item.name);

        const config = new HttpRequest('POST', `/dcm/instances/`, data, {
            reportProgress: true
        });

        return this._http.request(config)
            .pipe(
                tap(event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        item.progress = (event.loaded / event.total) * 100;
                    }
                }),
                filter(event => event.type === HttpEventType.Response),
                map((response: HttpResponse<any>) => response.body),
                debounce(() => timer(50)),
                catchError(() => of(null))
            );
    }

    private _uploadChunk(index, chunks, subject) {
        if (index === chunks.length) {
            subject.complete();
        } else {
            subject.next(chunks[index].file);
            chunks[index].subject.subscribe(data => subject.next(data), err => subject.error(err), () => {
                this._uploadChunk(index += 1, chunks, subject);
            });
        }
    }

    // private api for upload dicomization
    private _filesObservables(file, tags): Observable<any> {
        return this._convertToBase64(file)
            .pipe(
                mergeMap(base64 => this._getUID(2).pipe(
                    map(uid => new Object({
                        Force: true, Content: base64,
                        Tags: assign({SeriesInstanceUID: uid[0], SOPInstanceUID: uid[1]}, tags)
                    }))
                )),
                mergeMap(payload => this._generalPurpose.postHttp('/dcm/tools/create-dicom', payload))
            );
    }

    private _convertToBase64(file): Observable<string> {
        const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

        return from(promise) as Observable<string>;
    }

    private _getUID(count?) {
        return this._generalPurpose.getByEvent('explorer.generate_uid', {count})
            .pipe(
                map(data => data.uid)
            );
    }
}
