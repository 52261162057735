export const lang = {
    label: 'Paramètrages',

    search: 'Rechercher',
    save: 'Enregistrer',
    cancel: 'Annuler',
    actions: '',

    update_success: 'Paramétre modifié',
    no_modality_found: 'Aucune modalité trouvée',

    pacs: 'PACS',
    utilities: 'Utilitaires',

    pacs_general: {
        label: 'Paramétre de base',
        refresh: 'Actualiser le PACS',

        app_related: 'Paramètres d\'instance',

        app: 'Génerale',
        name: 'Nom de l\'instance',
        limit_find_result: 'Limite de la recherche des résultats',
        limit_find_instances: 'Limite de la recherche des instances',
        compression: 'Compression transparente des instances DICOM',
        case_sensitive: 'Correspondance sensible à la casse pour la valeur PN',
        strict_aet_comparison: 'Comparez AET de manière sensible à la casse',
        private_dictionary: 'Charger le dictionnaire privé',
        query_retrieve_size: 'Nombre maximal de requêtes Q/R simultanée',

        aet: 'Titre de l\'entité d\'application DICOM',
        port: 'Port',
        scp_timeout: 'Le délai (en secondes) avant les associations DICOM sont fermées',
        association_close_delay: 'Secondes d\'inactivité avant la fermeture d\'une association',

        dicom_server_enabled: 'Serveur PACS',
        dicom_check_called_aet: 'Vérifier AET',
        dicom_check_modality_host: 'Vérifie l\'adresse IP la modalité',

        dicom_web: 'DICOM WEB',
        dicom_web_enable: 'Activé',
        dicom_web_enable_wado: 'WADO Activé',
        dicom_web_qido_case_sensitive: 'QIDO sensible à la casse',
        dicom_web_ssl: 'Ssl',
        dicom_web_root: 'Root',
        dicom_web_wado_root: 'WADO root',
        dicom_web_wado_host: 'WADO host',
        dicom_web_wado_stow_max_instances: 'STOW nbr d\'instances maximal',
        dicom_web_wado_stow_max_size: 'STOW taille max',

        explorer_setting: 'Paramètres de l\'explorateur',
        explorer_available_modalities: 'Modalités disponibles',
        explorer_selected_modalities: 'Modalités sélectionnées',
        explorer_default_duration: 'Durée par défaut',

        pacs_host:  'URL extern du pacs (ex: https://pacs.demo.com)'
    },

    pacs_network: 'Résaux',
    pacs_transfer_syntax: 'Syntaxe de transfert',

    pacs_modalities: 'Gestion des modalités',
    pacs_modalitiy_new: 'Ajouté modalité',
    modality_remove_confirm: 'Voulez vous supprimé la modalité "{{title}}"',

    title: 'Titre',
    host: 'Address',
    port: 'Port',
    allow_echo: 'Echo',
    allow_find: 'Find',
    allow_move: 'Move',
    allow_store: 'Store',


    ian_nodes: {
        label: 'Gestion des nœuds (IAN)',
        new_item: 'Ajouter nœud',
        remove_confirm: 'Voulez vous supprimé la nœud "{{title}}"',
    },

    pacs_dictionaries: 'Dictionnaire',
    wado: 'WADO',
    staff: 'Gestion des acteurs',
    profiles: 'Gestion des profiles',
    staff_config: 'Listes des utilisateurs',

    archive: {
        label: 'Tâches d\'archivage',
        new: 'Ajouter tâches',
        trigger_time: 'Déclenchement',
        is_active: 'Active',
        is_active_form: 'Tâche active',
        directory: 'Répertoire',
        duration: 'Durée',
        used_space: 'Espace utilisé',
        modalities: 'Modalités',
        hard_drive_status: 'Espace disque (%)',
        task_remove_confirm: 'Voulez vous supprimer la tâche "{{title}}"',

        list: 'Liste des archives',
        nothing_found: 'Aucune archive trouvée',

        patient_id: 'ID patient',
        patient_name: 'Nom',
        study_date: 'Date d\'étude',
        study_description: 'Description',
        size: 'Taille',
        archived_at: 'Archivé le',

        search: 'Rechercher',
        study_date_search: 'Date',

        unarchive_confirm: 'Voulez vous desarchiver les "{{length}}" études selectionné ?',
        remove_confirm: `
            <span>Voulez vous supprimer définitivement les "{{length}}" études selectionné ?</span> <br />
            <strong class="ft-warn-color">CETTE ACTION EST IRRÉVERSIBLE !!</strong>
        `,

        initializing: 'Initialisation',
        uncompress: 'Décompression',
        current_item: '{{patient_name}} - {{study_description}}',
        handling_item: 'Traitment d\'instance n° {{current}}/{{total}}',

        '12_months': 'Une année',
        '9_months': '9 mois',
        '6_months': '6 mois',
        '3_months': '2 mois',
        '1_months': '1 mois',
        '15_days': '15 jours',
        '7_days': 'Une semaine',
    },

    dcm_upload: {
        dicomizer_btn: 'Dicomizer',

        label: 'Uploader des fichiers DICOM',
        drag_and_drop: 'Glisser et déposer ou cliquer',
        files_count: '{{count}} fichiers a dicomizer',

        file_progress: 'Fichier N° {{file.index}}/{{total}}'
    },

    burner: {
        label: 'Gestion des graveus',
        title: 'Titre',
        local_directory: 'Répertoire partagé (linux)',
        remote_directory: 'Répertoire original (windows)',
        trigger_time: 'Nettoyage à',
        setup_new: 'Configurer nouveau graveur',
        remove_confirm: 'Voulez vous supprimer la gravure "{{title}}"',

        burners: 'Graveus',
        bridge_is_active: 'Est active',
        rules: 'Règles',
        network: 'Réseau',

        add_rule: 'Ajouter régle',

        tasks: 'Tâches actuelles',

        rule_aet: 'AE titre',
        burner_name: 'Nom du robot graveur',

        // jobs
        burner_title: 'Titre du robot',
        job_patient: 'Patient',
        job_exam: 'Examen',
        job_exam_date: 'Date d\'examen',
        job_status: 'Status du tâche',
        job_created_at: 'Heure de déclenchement',
        refresh_jobs: 'Actualiser les tâches',

        // job status (epson related)
        JDF : 'Tâche créée',
        RJD : 'Tâche reçue',
        INP : 'Tâche en cours de traitemnt',
        DON : 'Tâche accomplie',
        ERR : 'Tâche comporte une erreur',
    },

    printing: 'Impression',
    printer: {
        label: 'Gestion des imprimantes',
        add_printer: 'Ajouter imprimante',

        title: 'Nom',
        aeTitle: 'AE titre',
        host: 'Address',
        port: 'Port',

        width: 'Largeur',
        height: 'Hauteur',
        add_paper: 'Ajouter paper',
        film_size: 'Film size ID',
        support_color: 'Couleur',
        supported_papers: 'Papiers supporté - Paysage',
        supported_types: 'Types de film supporté',
        supported_destinations: 'Destinations de films supporté',


        remove_confirm: 'Voulez vous supprimer l\'imprimente "{{description}}"',
    },

    print_scp: {
        label: 'Passerelle d\'impression',

        rules: 'Règles',
        add_rule: 'Ajouter Règles',
        remove_rule_confirm: 'Voulez vous supprimer la règles "{{aet}}"',

        papers: 'Papiers',
        network: 'Réseau',
        general: 'Géneral',
        is_active_form: 'Passerelle active',

        refresh: 'Actualiser la passerelle',

        aet: 'AET',
        port: 'Port',
        printer_name: 'Nom d\'imprimante',
        port_used: 'Ce port "{{port}}" est déjà utiliser !!',

        booklet: 'Livret',
        with_cover: 'Avec Couverture',
        cover_pages: 'Pages de garde',
        front_page: 'Première page',
        last_page: 'Dernière page',
    },

    hl7: {
        label: 'Serveur HL7',
        server_port: 'Port',
        server_enable: 'Serveur activé',
        port_used: 'Ce port "{{port}}" est déjà utiliser !!',
    },

    acl: {
        service: 'Services d\'établissement',
        service_hint: 'Services utilisant l\'application (requis pour les créations des utilisateurs)',
        staff: 'Comptes du personnel',
        staff_hint: 'Personnel qui vont utiliser l\'application',

        burner_config: 'Robots graveur',
        burner_config_hint: 'Récupération est nécessaire pour déclencher des tâches',
        burner_config_generate_job: 'Déclencher le gravage',

        printer: 'Client d\'impression',
        printer_hint: 'Création et mise à jour uniquement pour les administrateurs',
        printer_print: 'Lancer l\'impression',
        printer_generate_image: 'Aperçu du navigateur',
        print_scp: 'Serveur d\'imprimante',
        print_scp_hint: 'print scp (accès uniquement pour root)',

        app_setup: 'Paramètrages de l\'application',
        app_setup_hint: 'Accès à la page de configuration de l\'application',
        app_setup_update: 'Bouton de configuration dans la barre d\'outils',
        app_setup_reload_instance: 'Actualiser l\'instance du PACS',

        general_settings: 'Paramètres du PACS',
        general_settings_hint: 'Paramètres liés au PACS (accès uniquement pour root)',
        dicom_modality: 'Modalités DICOM',
        dicom_modality_hint: 'Modalités autorisées pour \'Query/Retrieve\' le PACS',
        ian_nodes: 'Nœuds IAN',
        ian_nodes_hint: 'Noeuds \'Instance availability notification\'',

        archive_task: 'Tâche d\'archivage',
        archive_task_hint: 'Tâches d\'archivage à long terme du PACS (création et mise à jour depuis la racine uniquement)',
        archive_task_search: 'Recherche',
        archive_task_unarchive: 'Désarchiver',

        explorer: 'Explorateur d\'études',
        explorer_hint: 'Interface principale PACS (accès minimum)',
        explorer_search: 'Recherche',
        explorer_download: 'Téléchargemet',
    }
};
