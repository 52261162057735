import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {assign} from 'lodash';
import {GeneralPurposeService} from '@ft/core';
import * as cornerstone from 'cornerstone-core';
import * as cornerstoneTools from 'cornerstone-tools';

import {ToolsService} from '../../services/tools.service';
import {ViewportState} from '../../models/viewport-conf';


@Component({
    selector: 'ftp-viewer-toolbar',
    templateUrl: './viewer-toolbar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        './viewer-toolbar.component.scss'
    ]
})
export class ViewerToolbarComponent implements OnInit {
    public layout = '1x1';
    public isOpen = false;
    public activeToolName = 'Wwwc';

    public scaleOverlay = true;
    public referenceLines = true;
    public wwccSynchronizer = false;
    public isPanZoomSynchronizerEnable = false;

    public readonly synchronizer: any;
    public fps: number[] = [1, 5, 10, 15, 24, 30, 40, 60];
    public layouts: string[] = ['1x1', '2x1', '3x1', '1x2', '2x2', '3x2', '1x3', '2x3', '3x3'];


    @Output() public openChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public layoutChange: EventEmitter<string> = new EventEmitter<string>();

    // tools related
    @Output() public currentToolChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() public scaleOverlayChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public referenceLinesChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    // state related
    @Input() public state: ViewportState = {};
    @Output() public stateChange: EventEmitter<ViewportState> = new EventEmitter<ViewportState>();

    constructor(
        private _cdf: ChangeDetectorRef,
        private _toolsService: ToolsService,
        private _generalPurpose: GeneralPurposeService,
        @Inject(DOCUMENT) private _document: Document
    ) {
        this.synchronizer = this._toolsService.synchronizer;
    }

    public ngOnInit() {
        this.setLayout(this.layout);
    }

    @Input('currentTool')
    public set handleCurrentTool(currentTool: string) {
        if (!currentTool) return;
        this.activeToolName = currentTool;
    }

    public handleSideNave() {
        this.isOpen = !this.isOpen;
        this.openChange.emit(this.isOpen);
    }

    public setLayout(layout) {
        this.layout = layout;
        this.layoutChange.emit(layout);
    }

    public setActiveTool(name) {
        this.activeToolName = name;
        this.currentToolChange.emit(name);
    }

    public setState(property, value) {
        this.state = assign({}, this.state, {[property]: value});
        this.stateChange.emit(this.state);
    }

    public updateScaleOverlay() {
        this.scaleOverlay = !this.scaleOverlay;
        this.scaleOverlayChange.emit(this.scaleOverlay);
    }

    public updateReferenceLines() {
        this.referenceLines = !this.referenceLines;
        this.referenceLinesChange.emit(this.referenceLines);
    }

    public enableSynchronizer() {
        this.wwccSynchronizer = !this.wwccSynchronizer;
        this._toolsService.wwwcSynchronizer.enabled = this.wwccSynchronizer;
    }

    public enablePanZoomSynchronizer() {
        this.isPanZoomSynchronizerEnable = !this.isPanZoomSynchronizerEnable;
        this._toolsService.panZoomSynchronizer.enabled = !this._toolsService.wwwcSynchronizer.enabled;
    }

    public resetViewport() {
        const element = this._activeViewport();
        cornerstone.reset(element);
    }

    public download() {
        this._generalPurpose.openPromptDialog('viewer.download_instance.dialog_title', 'viewer.download_instance.dialog_placeholder')
            .subscribe(title => cornerstoneTools.SaveAs(this._activeViewport() as any, `${title}.png`));
    }

    // to be used with actions that do not alter viewport states
    private _activeViewport(): HTMLElement {
        return this._document.querySelector('.viewport-wrapper.active .viewport:not(:empty)');
    }
}
